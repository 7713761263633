// import store from '@/store'
import request from '@/utils/request'

export const getAllBoosts = async () => {
  return request({
    url: '/boosts/all',
    method: 'get'
  })
}

export const getBoosts = async (page = 1, type) => {
  return request({
    url: '/boosts',
    method: 'get',
    params: {
      page,
      type,
      limit: 50
    }
  })
}

export const getTypes = async () => {
  return request({
    url: '/boosts/types',
    method: 'get'
  })
}

export const createBoost = async (data) => {
  return request({
    url: '/boosts',
    method: 'post',
    data
  })
}

export const deleteBoost = async (id) => {
  return request({
    url: `/boosts/${id}`,
    method: 'delete'
  })
}

export const updateBoost = async (id, data) => {
  return request({
    url: `/boosts/${id}`,
    method: 'put',
    data
  })
}

export const boostBulkAdd = async (id, data) => {
  return request({
    url: `/boosts/${id}/bulkadd`,
    method: 'put',
    data
  })
}

<template>
    <div>
        <section class="hero is-primary">
            <div class="hero-body">
              <p class="title">
                Guild Settings
              </p>
              <p class="subtitle">
                <strong>{{ guild.name }}</strong>
              </p>
            </div>
        </section>
        <div class="container mt-3">
          <b-message>
            <h1 class="is-size-3 has-text-weight-bold">Leveling</h1>

            <div class="columns">
                <div class="column is-1">
                  <b-field label="Enabled">
                    <b-switch v-model="guild.settings.levelEnabled" type="is-success"/>
                  </b-field>
                </div>

                <div class="column is-7">
                    <b-field label="Level Up Message">
                        <b-input v-model="guild.settings.levelUpMessage" :disabled="!guild.settings.levelEnabled" />
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Level Up Channel">
                        <b-autocomplete
                            :disabled="!guild.settings.levelEnabled"
                            v-model="levelUpChannelQuery"
                            placeholder="None"
                            :data="computedLevelUpChannels"
                            field="name"
                            @select="option => guild.settings.levelUpChannelId = option ? option.id : null"
                            open-on-focus
                            clearable />
                    </b-field>
                </div>
            </div>
          </b-message>

          <b-message>
            <h1 class="is-size-3 has-text-weight-bold">VIP</h1>

            <div class="columns is-multiline">
              <div class="column is-8">
                <b-field label="PayPal Plans">
                  <!-- TODO update plan -->
                  <b-taginput
                    ref="taginput"
                    v-model="guild.settings.premiumPlans"
                    type="is-dark"
                    :create-tag="addPlan"
                    block>
                    <template #selected="props">
                      <b-tag
                        v-for="(tag, index) in props.tags"
                        :key="index"
                        rounded
                        type="is-dark"
                        :tabstop="false"
                        ellipsis
                        closable
                        @close="$refs.taginput.removeTag(index, $event)">
                        {{ tag.name }}
                      </b-tag>
                    </template>
                  </b-taginput>
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Monthly Tickets">
                  <b-numberinput
                    v-model="guild.settings.premiumTickets"
                    placeholder="2000"
                    step="1"
                    min="0"
                    expanded controls-position="compact"
                    controls-alignment="right" />
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Boosts">
                  <multiselect
                    label="name"
                    track-by="id"
                    v-model="guild.settings.premiumBoosts"
                    :options="boosts"
                    multiple
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pick boosts"
                    :preselect-first="true">
                    <template #selection="{values, isOpen}">
                      <span
                        class="multiselect__single"
                        v-if="values.length && !isOpen">
                        {{ values.length }} boosts selected
                      </span>
                    </template>
                  </multiselect>
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="% Off">
                  <b-numberinput
                    v-model="guild.settings.premiumStoreOff"
                    placeholder="10"
                    step="1"
                    min="0"
                    max="100"
                    expanded controls-position="compact"
                    controls-alignment="right" />
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Role">
                  <b-select
                    required
                    v-model="guild.settings.premiumRoleId"
                    expanded>
                    <option
                      v-for="(role, i) in roles"
                      :key="i"
                      :value="role.id">
                      {{ role.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Welcome Channels">
                  <multiselect
                    label="name"
                    track-by="id"
                    v-model="guild.settings.premiumWelcomeChannelIds"
                    :options="channels"
                    multiple
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pick channels">
                    <template #selection="{values, isOpen}">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option }}</span>
                        </span>
                      </template>

                      <span
                        class="multiselect__single"
                        v-if="values.length && !isOpen">
                        {{ values.length }} channels selected
                      </span>
                    </template>
                  </multiselect>
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Welcome Message">
                  <div class="control">
                    <textarea
                      :disabled="!guild.settings.premiumWelcomeChannelIds.length"
                      v-model="guild.settings.premiumWelcomeMessage"
                      class="textarea has-fixed-size"
                      placeholder="Hey %roleMention%! %mention% is our new VIP! Hi!!!"
                      required="required" />
                  </div>
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Activation Message">
                  <div class="control">
                    <textarea
                      v-model="guild.settings.premiumActivatedMessage"
                      class="textarea has-fixed-size"
                      placeholder="Thank you for subscribing to the %plan% VIP plan!"
                      required="required" />
                  </div>
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Renewal Message">
                  <div class="control">
                    <textarea
                      v-model="guild.settings.premiumRenewedMessage"
                      class="textarea has-fixed-size"
                      placeholder="Your %plan% VIP subscription has been renewed!"
                      required="required" />
                  </div>
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Cancelled Message">
                  <div class="control">
                    <textarea
                      v-model="guild.settings.premiumCancelledMessage"
                      class="textarea has-fixed-size"
                      placeholder="Your VIP plan %plan% has been cancelled"
                      required="required" />
                  </div>
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Expired Message">
                  <div class="control">
                    <textarea
                      v-model="guild.settings.premiumExpiredMessage"
                      class="textarea has-fixed-size"
                      placeholder="Your VIP plan %plan% perks have expired. Thank you for being a subscriber!"
                      required="required" />
                  </div>
                </b-field>
              </div>
            </div>
          </b-message>

          <b-message>
            <h1 class="is-size-3 has-text-weight-bold">Nitro Boosting</h1>

            <div class="columns is-multiline">
              <div class="column">
                <b-field label="Boosts">
                  <multiselect
                    label="name"
                    track-by="id"
                    v-model="guild.settings.nitroBoosterBoosts"
                    :options="boosts"
                    multiple
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pick boosts"
                    :preselect-first="true">
                      <template #selection="{values, isOpen}">
                      <span
                        class="multiselect__single"
                        v-if="values.length && !isOpen">
                        {{ values.length }} boosts selected
                      </span>
                    </template>
                  </multiselect>
                </b-field>
              </div>

              <div class="column">
                <b-field label="Monthly Tickets">
                  <b-numberinput
                    v-model="guild.settings.nitroBoosterTickets"
                    placeholder="5000"
                    step="1"
                    min="0"
                    expanded controls-position="compact"
                    controls-alignment="right" />
                </b-field>
              </div>
            </div>
          </b-message>

          <b-message>
            <h1 class="is-size-3 has-text-weight-bold">Roles</h1>

            <div class="columns is-multiline">
              <div class="column is-4">
                <b-field label="Color role price">
                  <b-numberinput
                    v-model="guild.settings.colorRolePrice"
                    placeholder="1500"
                    step="1"
                    min="0"
                    expanded controls-position="compact"
                    controls-alignment="right" />
                </b-field>
              </div>
            </div>
          </b-message>

          <b-message>
            <h1 class="is-size-3 has-text-weight-bold">Voice Activity</h1>

            <div class="columns is-multiline">
              <div class="column is-4">
                <b-field label="VCClaim Tickets">
                  <b-numberinput
                    v-model="guild.settings.vcclaimTickets"
                    placeholder="75"
                    step="1"
                    min="0"
                    expanded controls-position="compact"
                    controls-alignment="right" />
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="VCClaim XP">
                  <b-numberinput
                    v-model="guild.settings.vcclaimXp"
                    placeholder="200"
                    step="1"
                    min="0"
                    expanded controls-position="compact"
                    controls-alignment="right" />
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="VCClaim time (how often)">
                  <b-input
                    v-model="guild.settings.vctime"
                    placeholder="15 mins"
                    required
                    expanded />
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="VC min members">
                  <b-numberinput
                    v-model="guild.settings.vcMinMembers"
                    placeholder="2"
                    step="1"
                    min="0"
                    expanded controls-position="compact"
                    controls-alignment="right" />
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="VC XP per min">
                  <b-numberinput
                    v-model="guild.settings.vcXPPerMinute"
                    placeholder="3"
                    step="1"
                    min="0"
                    expanded controls-position="compact"
                    controls-alignment="right" />
                </b-field>
              </div>
            </div>
          </b-message>

          <b-message>
            <h1 class="is-size-3 has-text-weight-bold">Calendars</h1>

            <div class="columns is-multiline">
              <div class="column is-6">
                <b-field label="Drivein Schedule">
                  <b-input v-model="guild.settings.drivein" />
                </b-field>
              </div>

              <div class="column is-6">
                <b-field label="Monthly Calendar">
                  <b-input v-model="guild.settings.calendar" />
                </b-field>
              </div>
            </div>
          </b-message>

          <b-message>
            <h1 class="is-size-3 has-text-weight-bold">Channels</h1>
            <p>See a ephemeral example <a href="https://cdn.discordapp.com/attachments/875754054150660159/931679453594152960/unknown.png" target="_blank">here</a></p>

            <div class="columns is-multiline">
              <div class="column is-4">
                <b-field label="Ephemeral Channels">
                  <multiselect
                    label="name"
                    track-by="id"
                    v-model="guild.settings.ephemeralChannelIds"
                    :options="channels"
                    multiple
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pick channels">
                    <template #selection="{values, isOpen}">
                      <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option }}</span>
                          </span>
                      </template>

                      <span
                        class="multiselect__single"
                        v-if="values.length && !isOpen">
                          {{ values.length }} channels selected
                        </span>
                    </template>
                  </multiselect>
                </b-field>
              </div>

              <div class="column is-4">
                <b-field label="Recommendations Channel">
                  <b-autocomplete
                    v-model="recommendationChannelQuery"
                    placeholder="None"
                    :data="computedRecommendationChannels"
                    field="name"
                    @select="option => guild.settings.recommendationChannelId = option ? option.id : null"
                    open-on-focus
                    clearable />
                </b-field>
              </div>
            </div>
          </b-message>

          <div class="columns has-text-right" v-if="hasPermission('guild.update')">
              <div class="column buttons">
                  <b-button
                      label="Reset"
                      type="is-primary"
                      @click="reset" />
                  <b-button
                      label="Save"
                      type="is-info"
                      @click="save" />
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import { hasPermission } from '@/utils/permissions'
import { getGuild, updateGuild } from '@/api/guild'
import { getAllBoosts } from '@/api/boosts'
import Multiselect from 'vue-multiselect'
import CreatePlanModal from './modals/CreatePlan'
import ms from 'ms'

export default {
  components: {
    Multiselect
  },
  data: () => ({
    madeChanges: false,
    fallbackGuild: {},
    boosts: [],
    guild: {
      settings: {
        id: '',
        levelUpMessage: '',
        drivein: '',
        calendar: '',
        vcclaimTickets: 0,
        vcclaimXp: 0,
        vcMinMembers: 0,
        vctime: 0,
        premiumStoreOff: 0,
        levelEnabled: false,
        levelUpChannelId: '',
        recommendationChannelId: '',
        premiumRoleId: '',
        premiumCancelledMessage: '',
        premiumActivatedMessage: '',
        premiumRenewedMessage: '',
        premiumWelcomeChannelIds: [],
        premiumWelcomeMessage: '',
        premiumExpiredMessage: '',
        ephemeralChannelIds: [],
        vcXPPerMinute: 0,
        nitroBoosterTickets: 0,
        colorRolePrice: 0,
        premiumTickets: 0,
        premiumPlans: [],
        premiumBoosts: [],
        nitroBoosterBoosts: []
      }
    },
    channels: [],
    roles: [],
    levelUpChannelQuery: '',
    roleQuery: '',
    recommendationChannelQuery: ''
  }),
  computed: {
    computedLevelUpChannels () {
      return this.channels.filter(c => c.name.includes(this.levelUpChannelQuery))
    },
    computedRecommendationChannels () {
      return this.channels.filter(c => c.name.includes(this.recommendationChannelQuery))
    },
    computedRoles () {
      return this.roles.filter(r => r.name.toLowerCase().includes(this.roleQuery.toLowerCase()))
    }
  },
  methods: {
    popPlan () {
      this.guild.settings.premiumPlans.pop()
    },
    addPlan (name) {
      let inserted = false

      this.$buefy.modal.open({
        parent: this,
        component: CreatePlanModal,
        hasModalCard: true,
        trapFocus: true,
        onCancel: () => {
          if (!inserted) {
            this.popPlan()
          }
        },
        events: {
          submit: (data) => {
            this.guild.settings.premiumPlans[this.guild.settings.premiumPlans.length - 1] = data
            inserted = true
          },
          close: () => {
            if (!inserted) {
              this.popPlan()
            }
          }
        },
        props: {
          name
        }
      })

      return { name }
    },
    hasPermission (permission) {
      return hasPermission(permission)
    },
    reset () {
      this.guild = { ...this.fallbackGuild }
    },
    async save () {
      try {
        await updateGuild({
          ...this.guild.settings,
          premiumWelcomeChannelIds: this.guild.settings.premiumWelcomeChannelIds.map(i => i.id),
          ephemeralChannelIds: this.guild.settings.ephemeralChannelIds.map(i => i.id),
          vctime: this.guild.settings.vctime ? ms(this.guild.settings.vctime) / 1000 : null
        })
        this.$buefy.notification.open({
          message: 'Guild updated successfully!',
          type: 'is-success'
        })
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    },
    async fetchData () {
      this.guild = await getGuild()
      this.boosts = await getAllBoosts()
      this.fallbackGuild = { ...this.guild }

      this.roles = Object.values(this.guild.roles).sort((a, b) => b.rawPosition - a.rawPosition)
      this.channels = Object.values(this.guild.channels)
        .filter(c => c.type === 'GUILD_TEXT')
        .sort((a, b) => a.rawPosition - b.rawPosition)

      if (this.guild.settings.levelUpChannelId && this.guild.channels[this.guild.settings.levelUpChannelId]) {
        this.levelUpChannelQuery = this.guild.channels[this.guild.settings.levelUpChannelId].name
      }

      if (this.guild.settings.recommendationChannelId && this.guild.channels[this.guild.settings.recommendationChannelId]) {
        this.recommendationChannelQuery = this.guild.channels[this.guild.settings.recommendationChannelId].name
      }

      this.guild.settings.premiumWelcomeChannelIds = this.guild.settings.premiumWelcomeChannelIds.map(c => this.channels.find(c2 => c2.id === c))
      this.guild.settings.ephemeralChannelIds = this.guild.settings.ephemeralChannelIds.map(c => this.channels.find(c2 => c2.id === c))

      this.guild.settings.vctime = this.guild.settings.vctime ? ms(this.guild.settings.vctime * 1000, { long: true }) : null
    }
  },
  mounted () {
    const load = this.$buefy.loading.open()
    this.fetchData().then(() => load.close())
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@/sass/darkmode.scss";

.multiselect .multiselect__tags,
.multiselect .multiselect__tags span,
.multiselect .multiselect__tags input {
  background-color: $ddarker;
  border-color: $ddarker;
  color: $text;
}

.multiselect .multiselect__content-wrapper {
  background-color: $ddark;
  border-color: $ddark;
  color: $text
}

.multiselect .multiselect__option--selected,
.multiselect .multiselect__option--highlight,
.multiselect .multiselect__option--highlight::after {
  background-color: $dblue;
  border-block: $dblue;
  color: $text;
}

</style>

<template>
    <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">CREATE A NEW PLAN</p>
    </header>
    <section class="modal-card-body">
      <b-field grouped>
        <b-field label="Id">
          <b-input
            v-model="form.id"
            placeholder="P-7CC28306T2909042SMHJB7YA"
            required
            expanded>
          </b-input>
        </b-field>
        <b-field label="Name" expanded>
          <b-input
            v-model="form.name"
            placeholder="Montly VIP"
            required
            expanded/>
        </b-field>
      </b-field>

      <b-field label="Description">
        <div class="control">
          <textarea
            v-model="form.description"
            class="textarea has-fixed-size"
            placeholder="A month long subscription for $5 to support our staff and development plans"
            required="required" />
        </div>
      </b-field>

      <b-field grouped>
        <b-field label="Price">
          <b-numberinput
            v-model="form.price"
            placeholder="5"
            step=".01"
            min="0"
            block controls-position="compact"
            controls-alignment="right" />
        </b-field>

        <b-field label="Cycle">
          <b-input
            v-model="form.cycle"
            placeholder="monthly"
            required
            expanded/>
        </b-field>
      </b-field>

      <div class="columns mt-5">
        <div class="column is-4 is-offset-8 buttons">
          <b-button
            label="Cancel"
            type="is-primary"
            @click="$emit('close')" />
          <b-button
            label="Submit"
            type="is-info"
            @click="submit" />
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.multiselect__content {
  z-index: 9999999999;
}
</style>

<script>
export default {
  data: () => ({
    form: {
      id: null,
      name: null,
      description: null,
      price: null,
      cycle: null
    }
  }),
  props: {
    name: String
  },
  beforeMount () {
    this.form.name = this.name
  },
  methods: {
    async submit () {
      this.$emit('submit', this.form)
      this.$emit('close')
    }
  }
}
</script>
